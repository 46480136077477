<template>
  <div v-if="!loading">
    <h3>My Account Info</h3>
    <br />
    <v-simple-table dense>
      <template  v-slot:default>
              <thead>
                <th style="width: 250px;"></th>
                <th></th>
            </thead>
        <tbody>
          <tr>
            <td>Name:</td>
            <td>{{ user.first_name }} {{ user.last_name }}</td>
          </tr>
          <tr>
            <td>Email:</td>
            <td>{{ user.email }}</td>
          </tr>
          <tr>
            <td>Phone:</td>
            <td>{{ user.phone }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <br />
    <h3>My Associated Companies</h3>
    <br />
    <div v-for="company in user.companies" :key="company.id">
      <v-simple-table dense>
        <template v-slot:default>
            <thead>
                <th style="width: 250px;"></th>
                <th></th>
            </thead>
          <tbody>
            <tr>
              <td>Company Name:</td>
              <td style="text-transform: capitalize;">{{ company.name }}</td>
            </tr>
            <tr>
              <td>Lender:</td>
              <td>{{ company.lender | yes_no}}</td>
            </tr>
            <tr>
              <td>Appraiser:</td>
              <td>{{ company.appraiser | yes_no}}</td>
            </tr>
            <tr>
              <td>Lawyer:</td>
              <td>{{ company.lawyer | yes_no}}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <br />
    </div>
  </div>
</template>

<script>
import API from "@/plugins/API";
import filters from "@/plugins/filters"
export default {
  data() {
    return {
      loading: true,
      user: {},
    };
  },
  filters: {
...filters
  },
  async mounted() {
    let userInfo = await API().get("/api/user-info");
    this.user = userInfo.data;
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
  tr:hover {
    background-color: transparent !important;
  }
</style>